import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/storage';

const firebaseConfig = {
	apiKey: "AIzaSyDv4Zsu4DMZuyyyii0CqWsWwizOH3M1HCM",
	authDomain: "emsbio-19cd7.firebaseapp.com",
	databaseURL: "https://emsbio-19cd7.firebaseio.com",
	projectId: "emsbio-19cd7",
	storageBucket: "emsbio-19cd7.appspot.com",
	messagingSenderId: "488594163478",
	appId: "1:488594163478:web:8023562e2219ef45eb3237",
	measurementId: "G-F5FFD3YT7Q"
};

// Initialize Cloud Firestore through Firebase
firebase.initializeApp(firebaseConfig);

const analytics = firebase.analytics();
const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();


export { firebase, storage, db, analytics, auth };
