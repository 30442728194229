export const routes = {
	// PLEASE ENSURE /APP/ is part of this... firebase hosting rewrite.
	private: {
		home: {
			path: '/app/home',
			name: 'PrivateHome',
			component: () => import(/* webpackChunkName: "private-home" */ '@/views/private/Home.vue'),
		},
		product: {
			path: '/app/product',
			name: 'PrivateProduct',
			component: () => import(/* webpackChunkName: "private-product" */ '@/views/private/Product.vue'),
		},
		resources: {
			path: '/app/resources',
			name: 'PrivateResources',
			component: () => import(/* webpackChunkName: "private-resources" */ '@/views/private/Resources.vue'),
		},
		users: {
			path: '/app/users',
			name: 'PrivateUsers',
			component: () => import(/* webpackChunkName: "private-users" */ '@/views/private/Users.vue'),
		},
		companies: {
			name: 'PrivateCompanies',
			path: '/app/companies',
			component: () => import(/* webpackChunkName: "private-companies" */ '@/views/private/Companies.vue'),
		},
		orders: {
			path: '/app/orders',
			name: 'PrivateOrders',
			component: () => import(/* webpackChunkName: "private-orders" */ '@/views/private/Orders.vue'),
		},
		'your-orders': {
			path: '/app/your-orders',
			name: 'PrivateYourOrders',
			component: () => import(/* webpackChunkName: "private-your-orders" */ '@/views/private/YourOrders.vue'),
		},
		leads: {
			path: '/app/leads',
			name: 'PrivateLeads',
			component: () => import(/* webpackChunkName: "private-leads" */ '@/views/private/Leads.vue'),
		},
	},
	// PUBLIC PAGES NEED TO BE ADDED TO VUE.CONFIG.JS FOR SEO
	public: {
		'/': {
			path: '/',
			redirect: '/home'
		},
		home: {
			path: '/home',
			name: 'Home',
			component: () => import(/* webpackChunkName: "public-home" */ '@/views/public/Home.vue'),
		},
		about: {
			path: '/about',
			name: 'About',
			component: () => import(/* webpackChunkName: "public-about" */ '@/views/public/About.vue'),
		},
		nextsteps: {
			path: '/nextsteps',
			name: 'NextSteps',
			component: () => import(/* webpackChunkName: "public-order" */ '@/views/public/NextSteps.vue'),
		},
		contact: {
			path: '/contact',
			name: 'Contact',
			component: () => import(/* webpackChunkName: "public-contact" */ '@/views/public/Contact.vue'),
		},
		product: {
			path: '/product',
			name: 'Product',
			// component: () => import(/* webpackChunkName: "public-product" */ '@/views/public/Product.vue'),
			component: () => import(/* webpackChunkName: "public-product" */ '@/views/public/ProductMW.vue'),
		},
		productMW: {
			path: '/product-mw',
			name: 'ProductMW',
			component: () => import(/* webpackChunkName: "public-product-mw" */ '@/views/public/ProductMW.vue'),
		},
		// medicare: {
		// 	path: '/medicare',
		// 	name: 'Medicare',
		// 	component: () => import(/* webpackChunkName: "public-reimbursement" */ '@/views/public/Reimbursement.vue'),
		// },
		// reimbursement: {
		// 	path: '/reimbursement',
		// 	name: 'Reimbursement',
		// 	component: () => import(/* webpackChunkName: "public-reimbursement" */ '@/views/public/Reimbursement.vue'),
		// },
		login: {
			path: '/login',
			name: 'Login',
			component: () => import(/* webpackChunkName: "public-login" */ '@/views/public/Login.vue'),
		},
		prequalify: {
			path: '/prequalify',
			name: 'Prequalify',
			meta: {
				disableNav: true, // will disable the navbar from showing for landing page etc.
			},
			component: () => import(/* webpackChunkName: "public-prequalify" */ '@/views/public/Prequalify.vue'),
		},
		landing: { // as a catch all send them to the landing page for prequalifying.
			path: '/:landingID',
			name: 'landing',
			meta: {
				disableNav: true, // will disable the navbar from showing for landing page etc.
			},
			component: () => import(/* webpackChunkName: "public-prequalify" */ '@/views/public/Prequalify.vue'),
		},
		'*': { // 404
			path: '*',
			name: '404',
			meta: {
				disableNav: true, // will disable the navbar from showing for landing page etc.
			},
			component: () => import(/* webpackChunkName: "public-prequalify" */ '@/views/public/Lost.vue'),
		},
	},
};
