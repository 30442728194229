import Vue from 'vue'
import VueRouter from 'vue-router'
import { analytics } from "@/helpers/firebase";
import { store } from '@/helpers/store';
import { bus } from '@/helpers/bus';
import { routes } from "@/router/routes";


Vue.use(VueRouter)

const routesMapped = [];

Object.keys(routes).forEach(layout => {
	Object.keys(routes[layout]).forEach(page => {
		const route = routes[layout][page];
		if (!route.meta) route.meta = {};
		route.meta.layout = layout;
		if (!route.props) route.props = {};
		route.props.layout = layout;
		routesMapped.push(route);
	})
});

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: routesMapped,
})

const isDev = window && window.location && window.location.hostname === 'localhost';


// firebase takes a second to load so we have to wait until we finish initing before we check the before route
let finishedAuth = false;
let toResolve;
bus.on('finishedAuthInit', () => {
	finishedAuth = true;
	if (toResolve) {
		toResolve(true);
	}
});

function ensureAuthFinished() {
	if (finishedAuth) {
		return Promise.resolve();
	} else {
		return new Promise((resolve) => {
			toResolve = resolve;
		})
	}
}

router.beforeEach(async (to, from, next) => {
	// always let public pages go through right away
	if (to.meta.layout === 'public' && to.path !== '/login') {
		next();
		return;
	}

	// special logic to let firebase finish auth
	await ensureAuthFinished();

	const user = store.get('systemUser');
	if (user) {
		if (to.path === '/login') {
			router.push('/app/home').catch();
			return;
		}
		next();
		return;
	}

	if (to.meta.layout === 'private') {
		router.push('/login').catch();
		return;
	}

	next();
});

router.afterEach((to) => {
	if (!isDev) analytics.logEvent(`page_view_${to.path}`);
})

export default router
