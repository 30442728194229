<template>
	<v-app>
    <theUnderConstruction v-if="!isNatty" />
		<div v-else class="ems-bio">
			<template v-if="isValidNavBarDef">
				<v-navigation-drawer
						v-model="drawer"
						temporary
						absolute
						width="200"
						id="drawer"
				>
					<v-list dense>
						<v-list-item-group
								v-model="selectedNav"
						>
							<v-list-item
									v-for="nav in navs"
									:key="nav.text"
									@click="$router.push(nav.to)"
									:value="nav.to"
							>

                <div class="something">
                  <div class="upper">
                    <v-list-item-icon>
                      <v-icon>{{ nav.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ nav.text }}</v-list-item-title>
                    </v-list-item-content>
                  </div>
                  <div class="lower">
                    <v-list-item-group
                        class="child-group"
                        v-if="nav.children"
                        v-model="selectedNav"
                    >
                      <v-list-item
                          v-for="nav2 in nav.children"
                          :key="nav2.text"
                          @click.stop="$router.push(nav2.to)"
                          :value="nav2.to"
                      >
                        <v-list-item-icon>
                          <v-icon>{{ nav2.icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>{{ nav2.text }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </div>

                </div>
							</v-list-item>
						</v-list-item-group>

					</v-list>
				</v-navigation-drawer>

				<v-app-bar
						app
						color="white"
						clipped-right
				>
					<div class="d-flex align-center page-logo">
						<img
								alt="EMS Bio Logo"
								class="shrink mr-2"
								src="@/assets/EMS-Bio_LogoFINAL_color_horiz_web.png"
								width="160"
						/>
						<div class="logo-sub-text">Authorized Distributor of BioLab Sciences</div>
					</div>
					<v-spacer></v-spacer>
					<v-toolbar-items class="hidden-sm-and-down">
						<v-btn
                @mouseenter="hoveredPath = nav.to"
                @mouseleave="hoveredPath = null"
								:class="{
              'nav__button--orange': $route.path === '/' && nav.to === '/home' || ($route.path !== '/' && $route.path.includes(nav.to) ) || hoveredPath === nav.to || selectedParent === nav.text,
            }"
								:text="$route.path === '/' && nav.to === '/home' ? false : !$route.path.includes(nav.to)"
								v-for="nav in navs" :key="nav.text"
                @click="!nav.children ? navigate(nav.to) : null"
						>
              {{ nav.text }}
              <div
                  v-if="nav.children && nav.children.length"
                   class="floating-menu"
                  :class="{
                    show: hoveredPath === nav.to,
                  }"
              >
                <v-btn
                    v-for="child in nav.children"
                    :key="child.text"
                    depressed
                    class="sub-menu-button"
                    :class="{
                  'nav__button--orange': $route.path !== '/' && $route.path === child.to,
                }"
                    @click="navigate(child.to)"
                >{{child.text}}</v-btn>
              </div>
						</v-btn>
						<v-btn
								text
								v-if="routeMeta.layout === 'private'"
								@click="logout"
						>
							<div class="user-display-name__container">
								<span
										class="user-display-name">{{ user.data ? `${user.data.name_first} ${user.data.name_last}` : '' }}</span>
								Logout
							</div>
						</v-btn>
					</v-toolbar-items>
					<v-icon class="hidden-md-and-up" @click="drawer = !drawer">fal fa-bars</v-icon>

				</v-app-bar>
			</template>

			<v-main>
				<router-view />
			</v-main>
		</div>
	</v-app>

</template>

<script>

import { auth, db } from '@/helpers/firebase';
import { store } from '@/helpers/store';
import { bus } from '@/helpers/bus';
import theUnderConstruction from "@/components/theUnderConstruction";

const navDefs = {
	private: [
		{
			text: 'Home',
			to: '/app/home',
			icon: 'fal fa-home'
		},
		{
			text: 'Product',
			to: '/app/product',
			icon: 'fal fa-flask'
		},
		{
			text: 'Resources',
			to: '/app/resources',
			icon: 'fal fa-book'
		},
		{
			text: 'Users',
			to: '/app/users',
			icon: 'fal fa-users',
			validateRole: ['admin'],
		},
		{
			text: 'Leads',
			to: '/app/leads',
			icon: 'fal fa-user',
			validateRole: ['admin'],
		},
		{
			text: 'Companies',
			to: '/app/companies',
			icon: 'fal fa-building',
			validateRole: ['admin'],
		},
		{
			text: 'Orders',
			to: '/app/orders',
			icon: 'fal fa-shopping-cart',
			validateRole: ['admin'],
		},
		{
			text: 'Orders',
			to: '/app/your-orders',
			icon: 'fal fa-shopping-cart',
			validateRole: ['companyID'],
		},
	],
	public: [
		{
			text: 'Home',
			to: '/home',
			icon: 'fal fa-home'
		},
		{
			text: 'About Us',
			to: '/about',
			icon: 'fal fa-address-card'
		},
    {
      text: 'Products',
      icon: 'fal fa-flask',
      to: '/product-mw'
    },
		// {
    //   text: 'Products',
    //   icon: 'fal fa-flask',
    //   children: [
    //     {
    //       parentKey: 'Products',
    //       text: 'Fluid GF',
    //       to: '/product',
    //       icon: 'fal fa-flask',
    //     },
    //     {
    //       parentKey: 'Products',
    //       text: 'Membrane Wrap',
    //       to: '/product-mw',
    //       icon: 'fal fa-flask',
    //     }
    //   ]
		// },
		// {
		// 	text: 'Reimbursement',
		// 	to: '/reimbursement',
		// 	icon: 'fal fa-heartbeat'
		// },
		{
			text: 'Next Steps',
			to: '/nextsteps',
			icon: 'fal fa-shopping-cart'
		},
		{
			text: 'Contact Us',
			to: '/contact',
			icon: 'fal fa-envelope'
		},
		{
			text: 'Login',
			to: '/login',
			icon: 'fal fa-sign-in-alt'
		}
	],
}

export default {
	name: 'App',
	components: {
    theUnderConstruction,
  },
	data() {
		return {
      isNatty: false,
      hoveredPath: null,
      selectedParent: null,
			hasInitAuth: false,
			drawer: false,
			selectedNav: '/home',
			routeMeta: {},
			navs: [],
			hasUser: false,
			isValidNavBarDef: false, // hide navbar for pages that arent registered above
			user: {},
		}
	},
	created() {
		// watch for chnage in authentication
		auth.onAuthStateChanged(async gUser => {
			const user = JSON.parse(JSON.stringify(gUser));
			if (user) {
				// User is signed in.
				this.hasUser = true;
				this.user = user;

				if (user.uid) {
					const that = this;
					await db.collection("users").doc(user.uid)
							.get()
							.then(async doc => {
								if (doc.exists) {
									that.user.data = doc.data();

									// get company info if they have any
									if (that.user.data.companyID) {
										await db.collection('companies')
												.doc(that.user.data.companyID)
												.get()
												.then(doc2 => {
													if (doc2.exists) {
														that.user.data.company = doc2.data();
													}
												})
									}

									store.set('systemUser', that.user);
								} else {
									// doc.data() will be undefined in this case
									console.log("No such user!");
								}
								bus.emit('userAuthFinished', that.user);
							})
							.catch(function(error) {
								console.log("Error getting companies: ", error);
								bus.emit('userAuthFinished', that.user);
							});
				}


				store.set('systemUser', this.user);
			} else {
				this.hasUser = false;
				store.set('systemUser', false);
			}

			// one time make sure we wait for the init to auth when the page is first loading
			if (!this.hasInitAuth) {
				this.hasInitAuth = true;
				bus.emit('finishedAuthInit', true);
			}

			this.ensureRoutingLogic();
		});
		this.ensureRoutingLogic();
	},
  mounted() {
    let params = new URLSearchParams(window.location.search);
    this.isNatty = params.get("natty"); // return john
  },
	methods: {
		logout() {
			auth.signOut().then(() => {
				// Sign-out successful.
				this.hasUser = false;
				this.$router.push('/login');
			}).catch(function(err) {
				console.error(err)
			});
		},
		requestedLogout() {
			this.$router.push('/home');
		},
		navigate(to) {
      this.hoveredPath = null;
      this.selectedParent = null;
			this.$router.push(to).catch(err => {
				// dont care about that message
				if (!err.message.includes('redundant navigation')) {
					console.error(err);
				}
			});
		},
		ensureRoutingLogic() {

			// set routeMeta if any
			if (this.$route.matched.length) {
				const { meta } = this.$route.matched[0];
				if (meta) {
					this.routeMeta = meta;
				}
			}

			// if they are logged in, but refreshed the page or something
			if (auth.currentUser && !this.hasUser) {
				this.hasUser = true;
			}

			this.navs = navDefs[this.routeMeta.layout || 'public'];

			this.navs = this.navs.filter(nav => {
				if (nav.validateRole) {
					const that = this;
					return nav.validateRole.some(requiredRole => {
						return that.user?.data?.[requiredRole];
					})
				}
				return true;
			})

			// set selected path
			const path = this.$route.path;

			this.navs.some(nav => {
				if (path === '/') {
					this.selectedNav = '/home';
					return true;
				}

        if (nav.children) {
          nav.children.some(nav2 => {
            if (path.includes(nav2.to)) {
              this.selectedParent = nav2.parentKey;
              this.selectedNav = nav2.to;
              return true;
            }
          })
        }

				if (path.includes(nav.to)) {
					this.selectedNav = nav.to;
					return true;
				}
			});

			// the / path happens super quick and makes the nav flash if we don't have this check in here for disableNav
			if (!this.$route.matched.length) {
				this.isValidNavBarDef = false;
			} else {
				this.isValidNavBarDef = !this.routeMeta.disableNav;
			}


		}
	},
	watch: {
		$route() {
			this.ensureRoutingLogic();
		},
	}
};
</script>

<style lang="scss">
// colors used = https://coolors.co/a8c686-ef946c-507dbc-a1c6ea-bbd1ea
// font awesome global imports
@import './assets/fonts/css/light.min.css';
@import './assets/fonts/css/solid.min.css';
@import './assets/fonts/css/fontawesome.min.css';

.page-logo {
	margin-bottom: .5rem;
}

.ems-bio {
	height: 100%;
}

.v-main {
	height: 100%;
}

.logo-sub-text {
	position: absolute;
	bottom: 0;
	font-size: 10px;
}

.text-color-green {
	color: #231f20 !important;
	fill: #231f20;
	//color: #64bb5c!important;
	//fill: #64bb5c;
}

.text-color-orange {
	//color: #fb7c34!important;
	//fill: #fb7c34;
	color: #00aeef !important;
	fill: #00aeef;
}

.text-color-yellow {
	color: #f1ec41;
}

.text-color-orangeReal {
	color: orange !important;
}

.details-header {
	font-weight: bold !important;
	color: #231f20;
	//color: #64bb5c;
	margin-bottom: 1rem;
	font-size: 18px;
	text-transform: uppercase;

	&__check {
		margin-right: 10px;
	}
}

.details {
	&__item {
		text-align: center;

		.details {
			display: flex;
		}
	}
}

.hero {
	display: flex;
	text-align: center;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	//background: #507DBC;
	background: #262262;
	//background: #00aeef;
	color: white;
	padding: 1rem;
}

.hero-sub {
	padding: 2rem;
	font-size: 24px;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}

.hero-highlight {
	//color: #fb7c34;
	color: #00aeef;
	//color: #ef9c6d;
}

.nav__button--orange {
	//background-color: #fb7c34!important;
	background-color: #00aeef !important;
	color: white !important;
}

</style>

<style lang="scss" scoped>
.floating-menu {
  flex-direction: column;
  position: absolute;
  top: 100%;
  margin-top: 1rem;
  display: none;
  background: white;
  box-shadow: 1px 4px 5px 0px rgba(0,0,0,0.75);
  &.show {
    display: flex;
  }
}
.child-group {
  margin-left: 0;
}
.sub-menu-button {
  margin: 5px;
}

.something {
  .upper {
    display: flex;
  }
}
.user-display-name {
	font-size: 10px;

	&__container {
		display: flex;
		flex-direction: column;
	}
}
</style>
