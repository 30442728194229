<template>
<!--  <?xml version="1.0" encoding="utf-8"?>-->
<!--  &lt;!&ndash; Generator: Adobe Illustrator 15.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  &ndash;&gt;-->
<!--  <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">-->
<!--  -->
  <div class="error-page">

    <!-- Start container  -->
    <div class="container">

      <!-- Start row  -->
      <div class="row">
        <div class="col-md-12">
          <div class="error">
            error
          </div>
          <svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               width="511.5px" height="331.333px" viewBox="-56.167 0 511.5 331.333" enable-background="new -56.167 0 511.5 331.333"
               xml:space="preserve">
	<g id="all-saw">
<g id="hand-with-saw">
	<path fill="#E8C8B3" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M311.025,103.367
		l4.167,1.792c0,0,0.917-2.125,3.292-2.125s3.672-1.094,4.756-1.037c1.082,0.057,2.702,2.487-2.506,3.637
		c-0.727,1.017-1.199,8.811-4.096,9.226c-1.213,0.174-3.475-0.588-5.26-2.664c-1.715-1.688,0.063-2.912-2.371-4.266"/>
  <path fill="#363838" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M310.517,121.767
		c0,0,4.35-5.665,6.585-7.895c7.088-7.055,9.528-5.306,11.22-3.99c1.377,1.071,3.176,2.896,3.799,3.54
		c2.229-1.616,3.737-2.699,3.737-2.699s-3.333-3.511-5.75-5.392s-5.905-4.38-16.036,5.703c-3.193,3.187-7.734,9.071-7.734,9.071
		s-5.441,5.124,3.83,12.332c0.408-0.461,1.373-1.29,2.668-2.333C306.863,125.215,310.517,121.767,310.517,121.767z"/>
  <g id="hand-left">
		<path fill="#CC582F" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M290.917,83.167
			c0,0,3.389,4.264,4.535,5.958c5.854,4.815,10.108,8.501,14.174,11.372c-1.252,1.884-3.785,5.138-3.785,5.138
			s-5.44-4.166-14.248-11.378c-0.491-0.818-1.684-1.803-4.219-6.089"/>
    <path fill="#CC582F" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M309,98.644
			c0,0-5.184,4.241-5.342,7.69c1.928,2.537,4.002,3.006,5.232,3.929c2.551-4.276,5.039-7.636,5.039-7.636
			S310.436,100.239,309,98.644z"/>
    <path opacity="0.3" fill="#231F20" enable-background="new    " d="M308.358,99.498c0,0-5.337,3.663-6.676,2.804
			c1.444,1.078,2.923,2.195,2.923,2.195L308.358,99.498"/>
	</g>
  <path fill="#B74A44" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M355.057,129.888
		l-23.202,25.182c0,0-4.291-1.706-9.598-7.466c-8.342-9.051-16.893-13.69-10.65-19.446c7.439-6.86,15.24-15.075,19.256-18.191
		c5.289-3.517,9.182-5.195,23.733,13.109C355.585,124.724,358.091,126.673,355.057,129.888z"/>
  <path fill="#E8C8B3" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M317.46,116.54
		c0,0,2.147-0.366,0.09-2.928c-2.057-2.562-3.289-1.937-3.815-1.243c-0.528,0.695-0.302,1.313,0.782,2.311
		C315.601,115.678,316.587,116.841,317.46,116.54z"/>
  <path fill="#E8C8B3" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M319.184,115.801
		c0,0,3.054-0.629,1.246-3.373c-1.808-2.743-3.425-3.002-4.017-2.361c-0.59,0.642-0.889,1.392,0.098,2.487
		C317.496,113.65,319.85,115.565,319.184,115.801z"/>
  <path fill="#E8C8B3" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M323.349,111.758
		c0,0,3.47-1.689,0.559-4.325c-2.293-2.078-3.75-2.083-4.166-1.375c-0.418,0.708-0.543,1.458,0.582,2.271
		S323.558,111.175,323.349,111.758z"/>
  <path fill="#E8C8B3" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M321.257,113.915
		c0,0,3.234-0.838,1.152-3.677c-2.082-2.84-3.828-3.029-4.427-2.311c-0.599,0.719-0.875,1.537,0.238,2.656
		C319.333,111.702,321.958,113.626,321.257,113.915z"/>
  <path fill="#CECECE" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M345.776,133.494
		l-8.698-7.829c0,0-3.547-3.479-6.759,0c-3.212,3.48-7.514,7.695-7.514,7.695s-4.427,3.835-1.501,6.635
		c2.925,2.8,10.632,9.09,10.632,9.09L345.776,133.494"/>
  <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M315.098,124.874l4.897,4.403c0,0,1.728,0.875,3.112-0.625c1.388-1.5-0.343-2.708-0.343-2.708l-4.557-4.076"/>
  <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M320.083,120.414l4.896,4.403c0,0,1.729,0.875,3.112-0.625c1.388-1.5-0.342-2.708-0.342-2.708l-4.557-4.076"/>
  <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M325.072,115.457l4.896,4.403c0,0,1.729,0.875,3.113-0.625c1.387-1.5-0.342-2.708-0.342-2.708l-4.558-4.076"/>

  <path fill="#363838" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M330.753,139.346c0,0-0.875,0.625-0.53,1.375c0.344,0.75,1.03,1.75,2.344,1.5s1.563-1.188,1.47-1.719
		C333.942,139.971,332.973,137.627,330.753,139.346z"/>
  <path opacity="0.3" fill="#231F20" enable-background="new    " d="M313.462,126.427c0,0-3.262,4.621,0,8.246
		s20.59,18.011,20.59,18.011l-2.064,2.242c0,0-7.229-3.315-11.683-9.627c-3.269-2.937-9.888-10.114-10.048-10.416
		C310.098,134.581,306.762,131.118,313.462,126.427z"/>
  <path opacity="0.3" fill="#231F20" enable-background="new    " d="M324.037,132.438c0,0-2.654,3.735-0.39,5.985
		s9.974,8.375,9.974,8.375l-1.709,1.75l-9.875-8.055C322.037,140.493,317.162,138.077,324.037,132.438z"/>
</g>
    <g id="shadow">
	<ellipse opacity="0.3" fill="#231F20" enable-background="new    " cx="283.828" cy="227.57" rx="31.834" ry="4.832"/>
</g>
    <g id="shaving">

		<polygon fill="#EFDBA3" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
		369.16,161.288 371.259,163.105 371.259,161.393 	"/>

      <polygon fill="#A07A5D" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
		369.16,161.288 367.533,165.478 371.259,163.105 	"/>

      <polygon fill="#EFDBA3" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
		367.294,153.042 369.396,154.858 368.654,152.655 	"/>

      <polygon fill="#A07A5D" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
		367.294,153.042 367.533,156.197 369.396,154.858 	"/>

      <polygon fill="#EFDBA3" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
		378.289,160.464 379.082,163.125 379.503,161.417 	"/>

      <polygon fill="#A07A5D" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
		378.289,160.464 377.332,162.749 379.082,163.125 	"/>

      <polygon fill="#EFDBA3" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
		359.3,166.119 361.755,167.417 360.833,165.918 	"/>

      <polygon fill="#A07A5D" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
		359.3,166.119 360.261,168.403 361.755,167.417 	"/>
</g>
    <g id="man">
	<path fill="#636D96" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M279.889,152.205
		c1.426,0.78,4.86,23.786,4.938,29.656c0.603,11.75,0.937,27.733,0.603,37.087c1.458-0.609,7.919-1.066,10.856-0.337
		c0.223-7.458,0.78-35.125,1.137-39.625c0.562-10.041-2.438-41.172-2.438-41.172c-2.715-1.816-4.63-1.92-37.285,1.989
		c0.653,5.255,2.174,34.327,1.911,40.241c0.103,5.578-1.014,31.436-1.472,37.728c7.833-1.042,9.438,0.049,10.896,0.84
		c0.375-4.583,3.438-31.313,4.5-36.875C273.955,170.605,277.632,150.972,279.889,152.205z"/>
      <path fill="#CC582F" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M267.533,66.862
		c0,0-8.781,7.671-10.717,31.105c-1.609,19.481,1.023,43.216,1.023,43.216s23.621,1.796,37.178-2.721
		c0.93-19.315-8.737-43.449,0.436-66.66C289.722,71.651,285.333,71.802,267.533,66.862z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M284.828,75.964c0,0-8.248,29.146-3.052,63.988"/>
      <path fill="#CC582F" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M272.72,74.205
		c0,0,1.217,3.216,8.027,6.073c1.862-1.798,3.008-3.078,4.354-5.231c1.411,1.977,2.988,5.367,2.988,5.367s2.73-0.848,4.311-4.945
		c0.186-1.797,0.244-2.372,0.244-2.372s-15.223-0.448-17.98-1.842C271.908,69.863,272.72,74.205,272.72,74.205z"/>
      <path fill="#E8C8B3" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M281.097,11.768
		c0,0-20.428-0.482-25.398,18.21c-2.986,12.933-1.205,19.661,1.314,29.291s10.18,14.699,25.294,15.469s26.946-4.44,29.304-22.845
		c1.743-13.601-3.738-31.539-10.613-35.918C294.313,11.718,290.071,11.207,281.097,11.768z"/>

      <line fill="none" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="288.44" y1="39.636" x2="288.229" y2="41.705"/>

      <line fill="none" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="305.317" y1="40.085" x2="305.141" y2="41.808"/>
      <path opacity="0.3" fill="#231F20" enable-background="new    " d="M259.31,57.693c0,0,8.872,14.362,19.186,0.483
		c3.553-4.782,10.336-6.207,16.698-6.517c9.079,0.207,10.825,4.279,11.896,7.926c0.572,1.953,3.078-0.984,3.078-0.984
		s-3.228,18.951-32.086,15.516c-21.188-3.536-20.668-15.203-20.668-15.203S258.583,58.495,259.31,57.693z"/>

      <path fill="#E8C8B3" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M297.96,44.333c0,0,2.188-0.022,3.326,0.776c1.109,0.766,1.592,1.793,0.914,3.246c-1.371,2.716-5.832,0.229-5.832,0.229"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M289.945,68.787c0,0,1.023,1.796,3.401,1.218c1.082-0.373,1.239-1.233,1.239-1.233s1.129,0.463,1.838,0.438
		c0.789-0.029,1.474-0.719,1.305-1.896"/>
      <path opacity="0.3" fill="#231F20" enable-background="new    " d="M296.341,48.833c0,0,0.084,1.857,2.082,2.171
		c1.996,0.313,2.601-1.691,2.601-1.691S298.751,49.877,296.341,48.833z"/>
      <path fill="#231F20" d="M258.214,143.145c0,0,21.42,1.213,36.803-3.195c-0.084-1.071,0-3.342,0-3.342s-0.424,3.123-36.877,3.494
		C258.192,141.015,258.214,143.145,258.214,143.145z"/>

      <path fill="#595453" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M262.979,139.497c0,0-0.708,0.31-0.505,1.866c0.201,1.558,0.179,2.499,0.977,2.507c0.797,0.008,1.022-0.793,0.793-2.326
		C264.013,140.013,263.667,139.375,262.979,139.497z"/>

      <path fill="#595453" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M291.179,137.284c0,0-0.762,0.146-0.904,1.709c-0.145,1.563-0.375,2.476,0.4,2.659c0.773,0.185,1.173-0.55,1.285-2.095
		C292.074,138.016,291.876,137.316,291.179,137.284z"/>

      <path fill="#9E9E9E" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M284.507,138.18c-0.995-0.71-4.401,0.141-4.865,0.218c0,0-1.171,0.305-0.969,2.084c0.203,1.78,0.393,2.835,1.301,2.86
		c0.908,0.025,5.339,0.217,5.441-2.047C285.449,140.574,285.509,138.896,284.507,138.18z M283.789,140.974
		c-0.053,1.168-2.338,1.068-2.808,1.056c-0.468-0.012-0.567-0.557-0.671-1.474c-0.104-0.921,0.499-1.077,0.499-1.077
		c0.239-0.038,1.999-0.478,2.51-0.113C283.837,139.736,283.805,140.602,283.789,140.974z"/>

      <path fill="#595453" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M271.21,139.334c0,0-0.735,0.237-0.689,1.807c0.046,1.57-0.07,2.502,0.721,2.591c0.791,0.087,1.096-0.688,1.021-2.234
		C272.187,139.95,271.908,139.28,271.21,139.334z"/>

      <path fill="#9E9E9E" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M282.765,140.243c0,0-0.232-0.619-2.59-0.128c-0.953,0.199-0.713,1.265-0.059,1.216
		C281.389,141.233,283.141,141.409,282.765,140.243z"/>
      <path opacity="0.3" fill="#231F20" enable-background="new    " d="M262.517,75.49c0,0,4.12,2.331,8.464,3.786
		c12.104,3.054,20.909,0.285,22.501-2.532c0.975-2.275,1.092-3.048,1.092-3.048s-13.197,4.12-29.52-3.299
		C264.147,71.344,262.517,75.49,262.517,75.49z"/>
      <path fill="#2C4C84" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M253.387,47.376
		c0,0,19.931-20.353,56.385-18.937c-1.103-4.1-6.876-28.39-36.595-20.563C240.437,16.498,253.387,47.376,253.387,47.376z"/>
      <path fill="#FFFFFF" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M273.177,7.876
		c-0.868,0.229-1.696,0.476-2.5,0.734c2.959,4.027,9.253,13.76,9.322,23.773c8.164-2.621,18.119-4.397,29.771-3.944
		C308.669,24.34,302.896,0.049,273.177,7.876z"/>
      <path fill="#E8C8B3" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M257.69,47.199
		c0,0-3.888-2.845-6.491,0.395c-3.505,4.356,0.176,8.563,1.422,9.56c2.879,2.308,5.76,2.084,7,0.133"/>

      <path fill="#E8C8B3" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M256.685,49.977c0,0-1.839-0.913-2.779,0.247c-0.939,1.16-0.074,3.568,1.182,4.035"/>
      <path opacity="0.3" fill="#231F20" enable-background="new    " d="M256.839,50.114l-0.297,1.25c0,0-2.293-0.954-2.701,0.896
		C253.512,51.419,253.237,48.971,256.839,50.114z"/>

      <path fill="#FFFFFF" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M286.14,58.364c0,0,9.893-2.242,14.975,0.028c-0.812,1.327-1.041,3.767-0.855,3.979c-1.745-0.275-10.559-1.516-14.561,0.296
		C285.251,61.8,284.863,60.455,286.14,58.364z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M285.328,60.394c0,0,6.625-1.275,10.498-0.516c3.873,0.759,4.586,0.425,4.586,0.425"/>
      <path opacity="0.3" fill="#231F20" enable-background="new    " d="M258.433,43.195c0,0,14.748-6.627,20.113-6.96
		c3.449,1.667,13.281,7.583,18.865,6.333s7.105-2.912,14.221-1.623c-0.633-4.369-2.303-11.044-2.637-12.544
		C306.41,28.29,278.705,27.155,258.433,43.195z"/>
      <path fill="#68553E" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M284.378,208.985
		c0,0,5.699,2.167,13.008,0c-1.084,3.479-0.926,10.75-0.926,10.75s3.867-3.417,7.951-1.917s5.334,4.333,5.5,7
		c-1.416,0.667-13.082,1.75-25.166-0.167C284.912,219.652,284.192,213.568,284.378,208.985z"/>
      <path fill="#D3C7BC" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M284.746,224.652
		c0,0-0.5,0.958-0.293,2.958c2.043,0.5,12.793,2.167,25.918,0c-0.043-1.583,0.041-1.875-0.459-2.792
		C308.703,225.11,293.037,226.568,284.746,224.652z"/>
      <path fill="#595453" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M293.578,222.902
		c0,0,1.076-1.962,2.883-3.167"/>
      <path fill="#68553E" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M257.845,208.168
		c0,0,5.521,2.59,12.971,0.978c-1.342,3.388-1.73,10.649-1.73,10.649s4.113-3.116,8.072-1.313c3.959,1.804,4.993,4.722,4.959,7.394
		c-1.463,0.56-13.178,0.764-25.084-2.057C257.576,218.846,257.314,212.726,257.845,208.168z"/>
      <path fill="#D3C7BC" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M257.035,223.819
		c0,0-0.572,0.918-0.515,2.928c1.999,0.652,12.593,3.121,25.844,1.947c0.077-1.582,0.183-1.865-0.247-2.818
		C280.89,226.077,265.158,226.353,257.035,223.819z"/>
      <path fill="#595453" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M265.973,222.738
		c0,0,1.22-1.875,3.11-2.94"/>
      <path fill="#363838" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M280,32.383
		c0,0,12.527,7.257,17.138,3.82c3.716-2.771,11.803-4.589,19.778-1.089c7.977,3.498,3.461-1.24,2.457-2.067
		c-1.002-0.828-3.521-3.115-9.602-4.607C307.339,28.117,290.154,27.879,280,32.383z"/>
      <path opacity="0.3" fill="#231F20" enable-background="new    " d="M271.095,9.188c0,0,6.816,12.547,5.232,21.214
		c-2.666,2.333-10.369,7.751-10.369,7.751l14.039-5.77C280,32.383,280.945,23.807,271.095,9.188z"/>
      <path opacity="0.3" fill="#231F20" enable-background="new    " d="M267.82,209.594c0,0-1.924,8.058-0.292,11.142
		c1.556-0.938,3.761-2.336,5.046-2.56c-1.205,1.644-4.078,4.346-2.33,7.557c-1.041,0.253-0.918,2.641-0.521,3.094
		c-1.867-0.145-12.979-2.152-12.979-2.152l0.496-5.354l0.604-13.148C257.845,208.168,262.021,210.745,267.82,209.594z"/>
      <path opacity="0.3" fill="#231F20" enable-background="new    " d="M294.457,209.763c0,0-1.306,8.181,0.558,11.131
		c1.479-1.054,3.569-2.615,4.836-2.935c-1.076,1.729-3.733,4.642-1.748,7.711c-1.021,0.33-0.715,2.703-0.285,3.123
		c-1.872-0.002-13.104-1.16-13.104-1.16l0.086-5.375l-0.396-13.157C284.402,209.101,288.762,211.351,294.457,209.763z"/>
      <polygon opacity="0.3" fill="#231F20" enable-background="new    " points="261.412,142.26 265.973,180.819 263.412,209.417
		258.787,208.485 259.617,175.838 257.937,142.26 	"/>
      <path opacity="0.3" fill="#231F20" enable-background="new    " d="M285.561,209.334l4.517,0.774l-0.582-29.542
		c0,0-1.697-22.221-9.35-28.027C281.328,156.152,286.787,175.485,285.561,209.334z"/>
</g>
    <g id="hand">
	<g id="hand-right">
		<path fill="#CC582F" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M269.194,85.382
			c0,0,22.293,16.417,35.521,24.513c-1.127,1.961-3.439,5.375-3.439,5.375s-22.107-13.797-33.963-24.657"/>
    <path opacity="0.3" fill="#231F20" enable-background="new    " d="M301.529,107.728c0,0-5.084,4.005-6.479,3.236
			c1.514,0.982,3.061,1.998,3.061,1.998L301.529,107.728"/>
	</g>
      <path fill="#E8C8B3" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M303.858,111.377
		l3.625,1.943c0,0,0.623-1.562,2.778-2.577c1.617-0.761,2.408-0.771,3.845,0c-1.625,1.85-1.453,1.707-1.453,1.707
		s5.245,1.956,4.964,4.3c-0.145,1.344-0.934,1.646-0.934,1.646s0.193,0.854-0.473,1.854c-0.668,1-1.543,0.813-1.543,0.813
		s0.086,1.125-1.207,1.875c-1.291,0.75-1.605,0.167-1.605,0.167s-0.707,1.654-1.792,1.57s-2.833-0.279-4.375-2.987
		c-0.958-1.5-0.04-3.019-0.04-3.019s-0.574-2.19-3.746-3.72"/>
      <path fill="#CC582F" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M302.061,106.888
		l-5.019,7.684c0,0,5.367,1.932,5.922,2.836c0.898-4.386,4.274-6.867,4.274-6.867S303.598,108.384,302.061,106.888z"/>
</g>
    <g id="_x34_04">
	<path opacity="0.3" fill="#231F20" enable-background="new    " d="M111.665,259.299c0,4.729-11.68,8.566-26.088,8.566
		c-14.407,0-37.432-3.893-37.432-8.621c0-4.73,23.025-8.512,37.432-8.512C99.985,250.734,111.665,254.57,111.665,259.299z"/>
      <ellipse opacity="0.3" fill="#231F20" enable-background="new    " cx="196.125" cy="257.775" rx="40.3" ry="9.112"/>
      <path fill="#937864" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M185.458,181.715
		c0,0-4,44.271,13.816,43.17c16.502-1.02,19.423-30.387,19.423-30.387l45.254,19.51c0,0-1.092,7.691-4.738,18.922
		c-3.489,10.753-15.578,32.54-56.982,32.541c-44.685,0.001-68.356-28.947-66.052-83.756
		C149.754,181.225,185.458,181.715,185.458,181.715z"/>
      <path fill="#A07A5D" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M2.401,175.543
		v29.23c0,0-1.732,26.493,22.071,29.207c15.047,1.717,39.124,2.82,39.124,2.82l0.58,22.498c0,0,3.075,7.789,26.642,7.031
		c16.235,0.028,19.427-8.826,19.427-8.826s0.154-4.92,0-18.602c-0.187-16.678,1.42-63.359,1.42-63.359H62.384l0.188,22.519
		l-13.244-0.166c0,0-6.739,0.167-6.111-9.467c0.308-4.716,0.469-5.688,0.854-12.886C39.679,174.379,12.506,173.273,2.401,175.543z"
      />

      <ellipse fill="#EFDBA3" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="23.236" cy="175.544" rx="20.835" ry="3.354"/>

      <ellipse fill="#EFDBA3" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="87.025" cy="175.543" rx="24.64" ry="3.967"/>

      <ellipse fill="#EFDBA3" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="160.817" cy="181.715" rx="24.64" ry="3.967"/>

      <ellipse fill="#EFDBA3" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="87.025" cy="175.327" rx="18.839" ry="2.093"/>

      <path fill="#EFDBA3" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M168.51,179.482c0,0,10.838,0.29,10.531,2.017c-0.203,1.137-8.434,2.092-18.839,2.092c-10.403,0-18.838-0.937-18.838-2.092
		c0,0-1.678-2.281,21.792-2.221"/>

      <ellipse fill="#EFDBA3" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="87.806" cy="175.228" rx="12.311" ry="0.806"/>

      <ellipse fill="#EFDBA3" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="160.983" cy="181.399" rx="12.311" ry="0.805"/>

      <ellipse transform="matrix(-0.9183 -0.3959 0.3959 -0.9183 382.0608 487.3342)" fill="#EFDBA3" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="241.318" cy="204.243" rx="24.64" ry="3.967"/>

      <ellipse transform="matrix(-0.9182 -0.3961 0.3961 -0.9182 380.7506 486.0116)" fill="#EFDBA3" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="240.557" cy="203.692" rx="18.839" ry="2.094"/>

      <ellipse transform="matrix(-0.9182 -0.3961 0.3961 -0.9182 382.1299 486.7264)" fill="#EFDBA3" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="241.318" cy="203.909" rx="12.311" ry="0.806"/>

      <ellipse fill="#EFDBA3" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="23.236" cy="175.327" rx="14.847" ry="1.649"/>

      <ellipse fill="#EFDBA3" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="23.852" cy="175.249" rx="9.703" ry="0.635"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M13.073,178.473c0,0-6.614,12.467-4.684,29.07c1.929,16.604,5.901,13.396,4.684,22.379"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M31.841,178.899c0,0-5.996,17.034-0.922,25.299c5.073,8.264,19.47,17.025,12.118,31.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M34.248,234.928c0,0,2.461-7.604-2.253-13.344s-8.758-9.332-8.758-15.887c0-13.066-7.109-8.379-7.001,0
		c0.104,8.088,7.471,18.803,1.356,26.623"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M28.88,234.444c0,0,1.538-3.984-0.345-8.377c-2.229-5.201-4.432-4.229-3.715-0.436c0.642,3.394-2.3,8.063-2.3,8.063"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M43.353,186.436c0,0-6.439,4.914-1.725,12.6c4.715,7.688,13.119,6.149,14.759,13.426"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M53.833,236.286c0,0,5.219-4.722,3.886-13.812"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M110.815,203.543c0,0,0.05-1.849-2.107-8.762c-2.157-6.914-2.844-16.684-2.844-16.684"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M94.567,179.323c0,0-0.754,18.227,4.147,25.299c4.901,7.072,10.769,18.612,11.515,30.978"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M81.958,179.51c0,0-1.23,15.887,1.691,30.338s22.265,27.056,26.66,42.275"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M69.352,178.473c0,0-0.479,11.91,0,19.089c1.084,16.233-8.447,21.315,4.612,35.347c17.885,19.217,7.737,33.254,7.737,33.254"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M63.627,237.983c0,0,11.259,8.607,4.819,25.006"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M51.366,197.92c0,0,8.095,1.396,9.837,6.276c1.742,4.884,3.659,0.986,1.368-6.136"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M74.886,204.622c0,0-3.565,11.74-2.063,17.25c1.503,5.512,7.766,6.654,13.026,11.782c5.26,5.128,2.38,0.118-0.752-5.019
		c-3.131-5.135-5.976-7.264-6.445-13.635C77.73,202.459,75.984,202.904,74.886,204.622z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M76.475,217.023c0,0-0.974-3.024-1.076,1.64s3.382,6.715,3.689,6.816c0.307,0.102,0.82-0.154-0.769-2.563"/>
      <path fill="#A07A5D" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M108.339,196.692
		c0,0,1.752,2.665,5.134,0.82c3.382-1.846,6.348-3.65,6.348-3.65l2.577,3.629c0,0-11.348,7.879-13.64,15.805"/>
      <path fill="#EFDBA3" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M119.52,194.334
		c0,0,0.237,2.099,1.947,2.973C124.147,198.676,120.263,191.872,119.52,194.334z"/>
      <path fill="#937864" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M145.484,229.704
		c0,0,1.036,3.697-1.938,6.522c-0.923,0.877-2.59,1.626-2.59,1.626l3.277,6.921c0,0,5.614-4.793,12.562-0.344"/>
      <path fill="#EFDBA3" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M141.903,237.856
		c0,0,2.996,2.316,2.745,5.753C144.254,248.993,137.434,236.704,141.903,237.856z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M120.592,196.635c0,0-9.629,5.596-11.884,9.738"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M145.953,184.879c0,0-10.362,10.184-8.686,22.795"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M160.817,185.683c0,0-17.678,10.507-18.193,42.806"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M177.437,184.644c0,0-7.661,18.163-6.161,28.987s0.656,27.022-14.922,35.757"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M153.484,209.715c0,0-0.25-5.752,4.875-7.901s7.375,8.04,6.25,16.819c-1.125,8.781-5.75,18.156-9.875,18.656
		s-5.125-10.357-4.5-13.806"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M159.943,215.538c0,0,1.333-3.166,0-4.25c-1.333-1.082-4.695,1.902-5.583,13.834c-0.888,11.93,4.167-2.834,4.417-6.084
		s-2,2.069-2.083,2.951"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M185.458,201.101c0,0-5.223,4.92-3.723,18.366c1.5,13.445,2.029,33.064-4.298,42.381"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M163.587,255.196c0,0,6.772-4.158,9.022-9.033s1.869,4.375,0.685,7.75s-2.472,5.332-2.472,5.332"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M194.3,223.484c0,0-2.69,12.972,9.643,22.972s13.098,17.926,13.098,17.926"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M256.501,213.797c0,0,3.396,5.986,0.832,22.859"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M233.639,204.776c10.471,15.617,12.514,37.682,8.942,50.191"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M202.28,224.638c0,0,5.566,10.649,14.761,14.524c9.193,3.875,10.443,9.449,10.693,10.226c0.25,0.774,0.625,3.399,3.25,3.399
		s2-11.593,0-15.482c-2-3.891-1-5.393-2.375-9.768s-4.582-9.736-14.166-14.994"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M217.53,227.983c0,0-1.5,1,0.75,3.5s5.188,3.563,3.5,0.688S218.968,226.67,217.53,227.983z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M214.546,225.476c0,0-1.875,0.339-1.188,3.013s2.302,4.799,3.683,5.987"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M219.921,236.038c0,0,3.75,2.125,4.75-0.688s0.625-5.813-2.375-8.375"/>
      <path opacity="0.3" fill="#231F20" enable-background="new    " d="M21.044,172.208c0,0-14.56,0.939-12.216,3.676
		c1.281,1.494,17.528,2.531,20.719,2.59c-9.944,0.375-25.5,0.313-26.813-3.146C1.547,172.868,21.044,172.208,21.044,172.208z"/>
      <path opacity="0.3" fill="#231F20" enable-background="new    " d="M155.03,178.083c0,0-14.829,1.326-12.485,4.062
		c1.281,1.495,17.71,3.049,20.901,3.104c-9.944,0.375-25.683-0.203-26.995-3.664C135.264,179.125,155.03,178.083,155.03,178.083z"/>
      <path opacity="0.3" fill="#231F20" enable-background="new    " d="M16.115,178.899c0,0-10.476,10.27-7.726,28.645
		s11.471,24.32,32.096,27.386c-16.012-0.946-32.479,1.938-37.802-24.687c-0.448-25.75,0-34.15,0-34.15S7.276,178.473,16.115,178.899
		z"/>
      <path opacity="0.3" fill="#231F20" enable-background="new    " d="M63.656,238.411c0,0,10.954,6.957,5.049,24.311
		c-1.774-0.33-4.146-2.172-4.537-3.764C64.109,256.034,63.656,238.411,63.656,238.411z"/>
      <path opacity="0.3" fill="#231F20" enable-background="new    " d="M382.662,261.896c0,4.729-11.68,8.565-26.088,8.565
		c-14.406,0-31.744-3.836-31.744-8.565s17.336-8.565,31.744-8.565S382.662,257.167,382.662,261.896z"/>
      <path fill="#A07A5D" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M273.398,178.141
		v29.23c0,0-1.732,26.494,22.07,29.207c15.048,1.715,39.125,2.82,39.125,2.82l0.58,22.499c0,0,3.074,7.788,26.642,7.031
		c16.235,0.026,19.427-8.826,19.427-8.826s0.154-4.92,0-18.603c-0.188-16.678,1.42-63.359,1.42-63.359H333.38l0.188,22.52
		l-13.242-0.166c0,0-6.74,0.166-6.111-9.469c0.308-4.715,0.469-5.688,0.854-12.884C310.675,176.976,283.503,175.87,273.398,178.141z
		"/>

      <ellipse fill="#EFDBA3" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="294.233" cy="178.141" rx="20.835" ry="3.354"/>

      <ellipse fill="#B3A47A" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="358.022" cy="178.14" rx="24.64" ry="3.967"/>

      <ellipse fill="#B3A47A" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="358.022" cy="177.923" rx="18.839" ry="2.093"/>

      <ellipse fill="#EFDBA3" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="358.802" cy="177.824" rx="12.313" ry="0.805"/>

      <ellipse fill="#EFDBA3" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="294.233" cy="177.923" rx="14.847" ry="1.649"/>

      <ellipse fill="#EFDBA3" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="294.849" cy="177.845" rx="9.703" ry="0.635"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M284.07,181.069c0,0-6.613,12.467-4.684,29.07c1.929,16.604,5.9,13.398,4.684,22.38"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M302.837,181.495c0,0-5.996,17.034-0.922,25.299c5.074,8.266,19.471,17.025,12.119,31.4"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M305.246,237.524c0,0,2.461-7.604-2.254-13.344c-4.714-5.74-8.758-9.332-8.758-15.887c0-13.066-7.109-8.377-7.001,0
		c0.104,8.088,7.472,18.804,1.356,26.625"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M299.876,237.042c0,0,1.539-3.983-0.344-8.377c-2.229-5.201-4.434-4.229-3.717-0.436c0.643,3.393-2.3,8.063-2.3,8.063"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M314.35,189.032c0,0-6.438,4.914-1.726,12.6c4.715,7.688,13.119,6.15,14.76,13.428"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M324.832,238.881c0,0,5.219-4.721,3.885-13.813"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M381.812,206.141c0,0,0.05-1.848-2.107-8.762c-2.156-6.914-2.844-16.685-2.844-16.685"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M365.564,181.918c0,0-0.754,18.228,4.146,25.299c4.9,7.072,10.77,18.613,11.516,30.977"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M352.955,182.107c0,0-1.229,15.886,1.691,30.337s22.266,27.057,26.66,42.276"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M340.349,181.069c0,0-0.479,11.911,0,19.09c1.084,16.232-8.447,21.315,4.611,35.346c17.885,19.218,7.738,33.254,7.738,33.254"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M334.625,240.579c0,0,11.26,8.608,4.819,25.006"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M322.363,200.517c0,0,8.096,1.396,9.838,6.276c1.741,4.883,3.658,0.985,1.367-6.135"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M345.882,207.217c0,0-3.564,11.74-2.063,17.25c1.505,5.512,7.767,6.654,13.026,11.783c5.26,5.127,2.38,0.117-0.754-5.02
		c-3.131-5.136-5.975-7.266-6.443-13.636C348.727,205.056,346.981,205.5,345.882,207.217z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M347.472,219.62c0,0-0.975-3.023-1.076,1.639c-0.102,4.664,3.382,6.716,3.689,6.816c0.307,0.102,0.82-0.154-0.771-2.563"/>
      <path fill="#A07A5D" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M379.335,199.29
		c0,0,1.752,2.664,5.135,0.819c3.382-1.845,6.348-3.649,6.348-3.649l2.577,3.629c0,0-11.349,7.877-13.64,15.805"/>
      <path fill="#EFDBA3" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M390.517,196.932
		c0,0,0.236,2.098,1.947,2.972C395.144,201.273,391.259,194.467,390.517,196.932z"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M391.589,199.231c0,0-9.629,5.596-11.885,9.738"/>
      <path opacity="0.3" fill="#231F20" enable-background="new    " d="M292.042,174.805c0,0-14.562,0.939-12.217,3.675
		c1.28,1.495,17.527,2.532,20.719,2.589c-9.943,0.375-25.5,0.313-26.813-3.146C272.544,175.463,292.042,174.805,292.042,174.805z"/>
      <path opacity="0.3" fill="#231F20" enable-background="new    " d="M287.112,181.495c0,0-10.476,10.271-7.726,28.646
		s11.471,24.319,32.096,27.385c-16.012-0.944-32.479,1.938-37.802-24.687c-0.448-25.75,0-34.148,0-34.148
		S278.273,181.069,287.112,181.495z"/>
      <path opacity="0.3" fill="#231F20" enable-background="new    " d="M334.653,241.006c0,0,10.954,6.957,5.05,24.31
		c-1.773-0.33-4.146-2.171-4.537-3.762C335.106,258.629,334.653,241.006,334.653,241.006z"/>
      <path opacity="0.3" fill="#231F20" enable-background="new    " d="M152.234,185.683c0,0-16.609,34.413,22.008,65.548
		c27.617,20.887,66.319,5.055,66.319,5.055s-29.181,18.987-68.316,3.598c-9.608-3.778-16.657-9.914-21.824-17.135
		c-2.282,0.021-5.125,1.149-6.593,2.231c-0.427-0.696-4.313-4.463-3-6.896c1.796-0.969,3.972-3.123,4.348-4.244
		c-10.642-21.289-8.749-46.414-8.776-51.59C139.984,184.118,149.862,185.811,152.234,185.683z"/>
</g>
<!--    <g id="saw-run">-->
<!--<g id="saw">-->
<!--	<path fill="#363838" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M381.567,184.443-->
<!--		c0,0-0.991,2.229,0.927,2.528c1.504,0.234,3.74-1.823,3.74-1.823s-1.732,0.378-2.158-0.59c-0.344-0.783,0.031-1.509,0.031-1.509-->
<!--		L381.567,184.443z"/>-->
<!--  <path fill="#363838" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M385.666,180.899-->
<!--		c0,0-0.07,2.439,1.816,1.988c1.48-0.354,2.77-3.105,2.77-3.105s-1.461,1.006-2.221,0.272c-0.615-0.595-0.544-1.407-0.544-1.407-->
<!--		L385.666,180.899z"/>-->
<!--  <path fill="#363838" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M388.167,176.452-->
<!--		c0,0,1.168,2.146,2.569,0.803c1.101-1.053,0.826-4.08,0.826-4.08s-0.753,1.607-1.778,1.355c-0.832-0.203-1.181-0.941-1.181-0.941-->
<!--		L388.167,176.452z"/>-->
<!--  <path fill="#363838" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M388.515,171.018-->
<!--		c0,0,2.066,1.301,2.635-0.555c0.446-1.455-1.27-3.963-1.27-3.963s0.125,1.771-0.893,2.051c-0.824,0.23-1.49-0.245-1.49-0.245-->
<!--		L388.515,171.018z"/>-->
<!--  <path fill="#363838" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M385.932,166.038-->
<!--		c0,0,2.405,0.419,2.228-1.513c-0.139-1.515-2.678-3.185-2.678-3.185s0.788,1.589-0.047,2.237c-0.676,0.523-1.471,0.336-1.471,0.336-->
<!--		L385.932,166.038z"/>-->
<!--  <path fill="#363838" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M381.181,162.257-->
<!--		c0,0,2.386-0.519,1.492-2.241c-0.7-1.352-3.682-1.94-3.682-1.94s1.329,1.175,0.801,2.089c-0.431,0.74-1.235,0.867-1.235,0.867-->
<!--		L381.181,162.257z"/>-->
<!--  <path fill="#363838" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M370.531,162.182-->
<!--		c0,0,0.938-2.254-0.986-2.505c-1.51-0.198-3.695,1.913-3.695,1.913s1.725-0.419,2.171,0.538c0.36,0.774,0.005,1.509,0.005,1.509-->
<!--		L370.531,162.182z"/>-->
<!--  <path fill="#363838" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M375.85,160.856-->
<!--		c0,0,1.806-1.644,0.168-2.687c-1.284-0.818-4.158,0.167-4.158,0.167s1.739,0.351,1.739,1.407c0,0.855-0.635,1.369-0.635,1.369-->
<!--		L375.85,160.856z"/>-->
<!--  <path fill="#363838" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M366.128,166.015-->
<!--		c0,0,0.014-2.441-1.862-1.944c-1.472,0.39-2.692,3.172-2.692,3.172s1.436-1.043,2.213-0.326c0.629,0.578,0.576,1.395,0.576,1.395-->
<!--		L366.128,166.015z"/>-->
<!--  <path fill="#363838" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M363.733,170.52-->
<!--		c0,0-1.219-2.115-2.589-0.74c-1.074,1.078-0.729,4.098-0.729,4.098s0.715-1.625,1.748-1.396c0.834,0.184,1.201,0.913,1.201,0.913-->
<!--		L363.733,170.52z"/>-->
<!--  <path fill="#363838" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M363.517,175.961-->
<!--		c0,0-2.096-1.252-2.621,0.617c-0.412,1.465,1.363,3.932,1.363,3.932s-0.168-1.766,0.844-2.072c0.818-0.248,1.494,0.21,1.494,0.21-->
<!--		L363.517,175.961z"/>-->
<!--  <path fill="#363838" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M366.218,180.877-->
<!--		c0,0-2.414-0.361-2.191,1.565c0.176,1.513,2.753,3.12,2.753,3.12s-0.825-1.57-0.006-2.236c0.664-0.541,1.462-0.371,1.462-0.371-->
<!--		L366.218,180.877z"/>-->
<!--  <path fill="#363838" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M371.059,184.543-->
<!--		c0,0-2.373,0.576-1.438,2.275c0.733,1.334,3.729,1.854,3.729,1.854s-1.355-1.145-0.85-2.069c0.41-0.75,1.213-0.896,1.213-0.896-->
<!--		L371.059,184.543z"/>-->
<!--  <path fill="#363838" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M376.156,185.938-->
<!--		c0,0-1.758,1.695-0.092,2.691c1.307,0.781,4.15-0.285,4.15-0.285s-1.748-0.301-1.777-1.356c-0.025-0.854,0.596-1.387,0.596-1.387-->
<!--		L376.156,185.938z"/>-->

<!--  <circle fill="#DCE6F9" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="375.865" cy="173.382" r="12.853"/>-->

<!--  <circle fill="#363838" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="375.865" cy="173.382" r="5.815"/>-->
<!--</g>-->

<!--			</g>-->
    <g id="wood">
	<path opacity="0.3" fill="#231F20" enable-background="new    " d="M306.337,316.723c-39.51,2.789-51.328,11.348-71.351,11.348
		s-32.661-3.568-32.661-7.973c0-4.402,12.641-7.971,32.661-7.971S310.73,316.413,306.337,316.723z"/>
      <path fill="#A07A5D" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M332.583,162.167
		l0.287,11.136c0,0,2.139,5.502,25.149,5.502c17.405,0.027,24.896-4.909,24.896-4.909s0.164-4.92,0-18.602
		c-0.201-16.678,1.522-24.36,1.522-24.36h-52.839l0.73,21.382L332.583,162.167"/>

      <ellipse fill="#EFDBA3" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="358.021" cy="130.933" rx="26.419" ry="3.967"/>

      <ellipse fill="#EFDBA3" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="358.021" cy="130.715" rx="20.199" ry="2.093"/>

      <ellipse fill="#EFDBA3" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="358.859" cy="130.616" rx="13.199" ry="0.805"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M382.985,163.25c0,0-3.172-5.086-5.485-12c-2.313-6.914,0.722-17.761,0.722-17.761"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M366.109,134.71c0,0-1.488,13.086,3.766,20.158c5.256,7.072,6.985,6.756,6.985,21.532"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M352.589,134.9c0,0,1.826,27.85,0.769,43.789"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M331.867,138.668c0,0,13.793,5.082,9.154,38.352"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M366.064,178.435c0,0,1.369-7.05-0.688-13.382"/>
      <path fill="#A07A5D" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M275.018,264.917
		l-51.136,0.309c0,0-5.498,2.146-5.459,25.158c0,17.408,4.951,24.891,4.951,24.891s44.92,0.154,58.602-0.031
		c16.678-0.23,24.363,1.48,24.363,1.48l-0.088-52.84l-21.381,0.769L275.018,264.917"/>

      <ellipse transform="matrix(-0.0017 -1 1 -0.0017 16.4978 597.1094)" fill="#EFDBA3" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="306.296" cy="290.32" rx="26.419" ry="3.969"/>

      <ellipse fill="#EFDBA3" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="306.52" cy="290.313" rx="2.093" ry="20.197"/>

      <ellipse fill="#EFDBA3" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="306.623" cy="291.151" rx="0.805" ry="13.199"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M274.019,315.321c0,0,5.081-3.182,11.99-5.504c6.91-2.324,17.763,0.689,17.763,0.689"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M302.53,298.397c0,0-13.635-3.775-26.013,0.85c-8.253,3.084-40.883,9.947-55.659,9.973"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M298.515,264.163c0,0-45.059,13.801-78.336,9.217"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M218.421,290.383c0,0,42.246,10.988,84.106-8.721"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M285.183,277.122c0,0-1.083-2.832-17.333,0.334s-18.584,3.664-27.167,4.207s-5,3.209,2,4.125
		C249.683,286.706,292.266,287.788,285.183,277.122z"/>
      <path fill="#937864" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M260.639,290.938
		l-1.517,31.123c0,0-2.246,5.459-25.252,5.008c-17.406-0.313-24.797-5.396-24.797-5.396s0.94-24.918,1.375-38.596
		c0.527-16.67-1.045-24.386-1.045-24.386l52.829,1.039l-1.149,21.364L260.639,290.938"/>

      <ellipse transform="matrix(0.9998 0.0197 -0.0197 0.9998 5.1527 -4.5962)" fill="#EFDBA3" stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="235.831" cy="259.199" rx="26.418" ry="3.968"/>

      <ellipse transform="matrix(0.9998 0.0198 -0.0198 0.9998 5.1801 -4.6241)" fill="#EFDBA3" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="235.845" cy="258.991" rx="20.199" ry="2.092"/>

      <ellipse transform="matrix(0.9998 0.0194 -0.0194 0.9998 5.0627 -4.5067)" fill="#EFDBA3" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="234.991" cy="258.886" rx="13.199" ry="0.805"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M210.226,291.032c0,0,3.271-5.023,5.72-11.891c2.449-6.868-0.372-17.771-0.372-17.771"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M227.66,262.829c0,0,3.53,13.7-1.316,25.994c-3.231,8.196-10.971,20.699-11.262,35.473"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M261.817,267.458c0,0-14.899,24.808-10.915,58.16"/>
      <path fill="none" stroke="#231F20" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
		M233.871,327.069c0,0-9.938-22.438,10.521-63.939"/>
      <path opacity="0.3" fill="#231F20" enable-background="new    " d="M261.505,273.258c0,0,2.23,20.395,41.215,28.262
		c0.195,2.393,0.505,13.537,3.619,15.203c-5.262-0.711-25.867-3.094-46.992-1.402C260.347,306.012,261.505,273.258,261.505,273.258z
		"/>
      <path opacity="0.3" fill="#231F20" enable-background="new    " d="M221.792,262.303c0,0-3.524-0.041-3.371,5.959
		c0.154,6-3.709,55.42,5.91,58.084c-5.283-0.965-13.679-2.965-15.123-4.588c0.031-4.498,2.498-56.823,0.371-62.035
		C211.537,260.679,217.214,262.428,221.792,262.303z"/>
</g>
	</g>
</svg>
          <h1 class="no-found">Page Not Found</h1>
          <p class="error-text">The page you are looking for might have been removed, had its name changed,
            or is temporarily unavailable.</p>
<!--          <a href="" class="btn"><i class="uf uf-arrow-left"></i>Go back</a>-->
        </div>
      </div>
      <!-- End row  -->

    </div>
    <!-- End container -->

  </div>

</template>

<script>
export default {
  name: 'theUnderConstruction',
}
</script>

<style>
body {
  font-family: 'Montserrat', sans-serif;
  background-color: #f8f9fb;
}

a,
a:hover,
a:visited {
  text-decoration: none;
}

.btn {
  color: #fefefe;
  background-color: #9f795d;
  line-height: 38px;
  display: inline-block;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 700;
  border-radius: 0;
  margin-right: 10px;
  text-transform: uppercase;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.btn:hover {
  color: #fefefe;
  background-color: #85644d;
}

.btn i {
  font-weight: 400;
  line-height: 24px;
  margin-right: 5px;
  font-family: UkieFont;
  font-size: 10px;
}

.error-page {
  padding-top: 25vh;
  text-align: center;
  display: block;
  margin: 0 auto auto;
}

.no-found {
  color: #313131;
  font-size: 48px;
  font-weight: 700;
  line-height: 24px;
}

.error-text {
  color: #313131;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  width: 50%;
  margin: 30px auto;
}

.uf-copyright {
  font-size: 10px;
}

.copyright {
  color: #c7c7c7;
  font-weight: 300;
  line-height: 24px;
  bottom: 0;
  text-align: center;
  display: block;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 50px;
}

.error {
  color: #eaebed;
  font-size: 250px;
  font-weight: 700;
  line-height: 0;
}

/* ------------------
    Animation
--------------------*/

#saw-svg {
  width: 120px;
  height: 331px;
}

#hand {
  animation: hand 150ms infinite linear;
  transform-origin: 0% 3%;
  -moz-animation: hand 150ms infinite linear;
  -moz-transform-origin: 48% 27%;
  -ms-transform-origin: 0% 100%;
}

@keyframes hand {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@-moz-keyframes hand {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

#hand-with-saw {
  animation: hand-with-saw 150ms infinite linear;
  transform-origin: 0% 3%;
  -moz-animation: hand-with-saw 150ms infinite linear;
  -moz-transform-origin: 48% 27%;
  -ms-transform-origin: 0% 100%;
}

@keyframes hand-with-saw {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@-moz-keyframes hand-with-saw {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

#saw {
  animation: saw 0.3s infinite linear;
  transform-origin: 50% 50%;
  -moz-animation: saw 0.4s infinite linear;
  -moz-transform-origin: 89% 52%;
  -ms-transform-origin: 0% 100%;
}

@keyframes saw {
  0% {
    transform: rotate(360deg);
  }
}

@-moz-keyframes saw {
  0% {
    -moz-transform: rotate(-360deg);
  }
}

#saw-run {
  animation: saw-run 150ms infinite linear;
  transform-origin: 0% 3%;
  -moz-animation: saw-run 150ms infinite linear;
  -moz-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 100%;
}

@keyframes saw-run {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@-moz-keyframes saw-run {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

#man {
  animation: man 150ms infinite linear;
  transform-origin: 0% 0%;
  -moz-animation: man 150ms infinite linear;
  -moz-transform-origin: 46% 39%;
  -ms-transform-origin: 0% 100%;
}

@keyframes man {
  0% {
    transform: translateY(-1px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(1px);
  }
}

@-moz-keyframes man {
  0% {
    -moz-transform: translateY(-1px);
  }
  50% {
    -moz-transform: translateY(0);
  }
  100% {
    -moz-transform: translateY(1px);
  }
}

#shaving {
  animation: shaving 0.5s infinite linear;
  transform-origin: 0% 0%;
  -moz-animation: shaving 0.5s infinite linear;
  -moz-transform-origin: 46% 39%;
  -ms-transform-origin: 0% 100%;
}

@keyframes shaving {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(40px, -30px);
    opacity: 0;
  }
}

@-moz-keyframes shaving {
  0% {
    -moz-transform: translate(0, 0);
  }
  100% {
    opacity: 0;
    -moz-transform: translate(40px, -30px);
  }
}

/* ------------------
    Media Query
--------------------*/

@media (max-width: 425px) {
  #all-saw {
    transform: matrix(0.85, 0, 0, 0.85, 0, 0);
  }

  #saw-svg {
    width: 350px;
    height: 282px;
  }
}

@media (max-width: 320px) {
  #all-saw {
    transform: matrix(0.75, 0, 0, 0.75, 0, 0);
  }

  #saw-svg {
    width: 300px;
    height: 250px;
  }
}

@media (max-width: 992px) {
  .copyright {
    margin: 30px auto 0;
    bottom: inherit;
    padding: 0 15px 30px 15px;
    position: relative;
    width: 80%;
    display: block;
  }

  .error-text {
    width: 100%;
  }

  .no-found {
    line-height: 56px;
  }
}

@media (max-width: 650px) {
  .error {
    font-size: 180px;
  }
}

@media (max-width: 480px) {
  .no-found {
    line-height: 50px;
    font-size: 46px;
    margin-top: 20px;
  }

  .error-text {
    margin: 20px auto;
  }

  .error {
    font-size: 100px;
  }
}

@media screen and (max-width: 375px) {
  .error-page {
    padding-top: 20vh;
  }
}
</style>
