/** *****
 * Allows components to subscribe (on) to and emit events for component to component communication
 * bus.on: subscribes to an event
 * bus.off: unsubscribes from an event
 * bus.emit: emits an event happened with it's data payload
 * bus.debug: consoles all events with a count of their listeners
 ****** */

const busMe = () => {
	const eventListeners = {};

	// helper function to find the index of the callback in a list of objects
	const getIndexOfListener = (listeners, callback) => listeners.findIndex(listenerObj => listenerObj.callback === callback);

	// helper function to create needed object if not there and return the current event listeners callbacks.
	const getListeners = (eventName) => {
		// return all listeners
		return eventListeners[eventName] ? eventListeners[eventName] : eventListeners[eventName] = [];
	};

	// clear all listeners
	const clearListeners = () => {
		Object.keys(eventListeners).forEach((key) => {
			delete eventListeners[key];
		});
	};

	/**
	 * Start listening to an event
	 * @param eventName {String}
	 * @param callback {Function}	Listener to called when event is emitted
	 * @param options {Object}
	 *	debug: should we console info
	 *	limit: {Number} number of times to call (i.e. only call it once then remove it = 1)
	 */
	const on = (eventName, callback, options = {}) => {
		let debug;
		let limit;
		if (options) ({ debug, limit } = options);

		if (!eventName || !callback) {
			console.error('"eventName" and "callback" Required to ride the event bus ;)');
			return;
		}
		// create new listener
		const listeners = getListeners(eventName);
		const newListener = {
			callback,
		};
		// limit callback to a number of times to call callback if specified.
		if (limit) newListener.limit = limit;
		if (debug) newListener.debug = true;

		// check if there are already listeners
		if (listeners.length) {
			// ensure we don't have duplicate listeners
			const hasListenerIndex = getIndexOfListener(listeners, callback) >= 0;
			// de-duplicated add listener
			if (!hasListenerIndex) {
				listeners.push(newListener);
			} else if (debug) console.log(`Duplicate subscription to ${eventName}`);
		} else {
			listeners.push(newListener);
		}
	};

	/**
	 * Stop listening to an event
	 * WARNING If no eventName is passed it will clear all listeners
	 * WARNING if no callback is passed it will clear all listeners for an eventName
	 * @param eventName {String} name of the event that is being listened
	 * @param callback {Function}	Listener to check for to stop listener
	 * @param options {Object}
	 *	debug: should we console info
	 */
	const off = (eventName, callback, options = {}) => {

		let debug;
		if (options) ({ debug } = options);

		// if no event name clear all
		if (!eventName) {
			clearListeners();
			if (debug) console.log(`Clearing listeners`);
		} else {
			const listeners = getListeners(eventName);
			// ensure there are listeners to remove
			if (!listeners.length) {
				if (debug) console.info(`No listeners to kick off the bus for event: ${eventName}`);
				return;
			}
			// if no callback clear listeners for that eventName
			if (!callback) {
				// clear all from Array.
				listeners.splice(0, listeners.length);
			} else {
				// find index of callback to remove
				const listenerIndex = getIndexOfListener(listeners, callback);
				// ensure there is one to remove
				if (listenerIndex >= 0) {
					listeners.splice(listenerIndex, 1);
				} else if (debug) console.info('No such callback to kick off the bus.');
			}
		}

	};

	/**
	 * Emit an event to the shell event bus
	 * @param eventName {String}
	 * @param payload {*} Payload of whatever you want to send
	 * @param options {Object}
	 *	namespace: Namespace to emit to if other than default
	 *	debug: should we console info
	 */
	const emit = (eventName, payload, options = {}) => {
		// support ns or namespace
		if (options.ns) options.namespace = options.ns;

		// set options if any
		let namespace;
		let debug;
		if (options) ({ namespace, debug } = options);

		const listeners = getListeners(eventName, namespace);

		// ensure there are any to call
		if (!listeners.length) {
			if (debug) console.info(`No listener riding the event bus for event: "${eventName}"`);
			return;
		}

		const listenersIndexesToRemove = [];

		// loop and broadcast event happened ALSO see if it's been limited to a number of calls
		listeners.forEach((callback, i) => {

			// check if there's a limit that needs to be enforced for the callback
			// eslint-disable-next-line no-prototype-builtins
			if (callback.hasOwnProperty('limit')) {
				if (callback.limit) {
					callback.callback(payload);
					callback.limit--;
					// check if we should remove due to limit
					if (callback.limit <= 0) {
						listenersIndexesToRemove.push(i);
						if (debug || callback.debug) console.log(`Removed listener from ${eventName} due to limit`);
					}
				}
			} else {
				callback.callback(payload);
			}

		});
		// remove unneeded listeners that
		if (listenersIndexesToRemove.length) {
			listenersIndexesToRemove.forEach(index => listeners.splice(index, 1));
		}
	};

	// console number of listeners per event
	const debug = () => {
		// loop through event listeners and get a count for the number of listeners for each event and console it.
		const toReturn = {};
		Object.keys(eventListeners).forEach(eventName => {
			toReturn[eventName] = eventListeners[eventName].length;
		});
		return toReturn;
	};

	return {
		on,
		off,
		emit,
		debug,
	};
};

export const bus = busMe();
