/** *****
 * Shell State System
 * Allows components data communication via a store as well as watchers to subscribe to changes to state properties
 * These are namespace'd by app automatically.  If you need to access another you can pass it through the "options" param {namespace: ''}
 * @param initialState {Object} object containing the state that we should start with.
 * state.set
 * state.get
 * state.watch
 ****** */
import { bus } from '@/helpers/bus';

/**
 * Does the property have any value
 */
const hasValue = (value) => {
	if (Array.isArray(value)) return !!value.length;
	if (value === undefined || value === null) {
		return false;
	}

	if (value === false) {
		return true;
	}

	if (value instanceof Date) {
		// invalid date won't pass
		return !Number.isNaN(value.getTime());
	}

	if (typeof value === 'object') {
		Object.keys(value)
			// eslint-disable-next-line consistent-return
			.forEach(k => {
				if (Object.prototype.hasOwnProperty.call(value, k)) return true;
			});

		return false;
	}

	return !!String(value).length;
};
const deepEqual = (x, y) => {
	if (x === y) {
		return true;
	}
	if ((typeof x === 'object' && x != null) && (typeof y === 'object' && y != null)) {
		if (Object.keys(x).length !== Object.keys(y).length) return false;

		// eslint-disable-next-line no-restricted-syntax
		for (const prop in x) {
			if (Object.prototype.hasOwnProperty.call(x, prop)) {
				if (!deepEqual(x[prop], y[prop])) return false;
			} else {
				return false;
			}
		}

		return true;
	}
	return false;
};

const storeMe = (initialState = {}) => {

	/** ************
	 * Private variables
	 ************ */
	const _state = JSON.parse(JSON.stringify(initialState));

	/** ************
	 * Private functions
	 ************ */

	/** ************
	 * Public functions
	 ************ */

	/**
	 * Retrieve a copy of the state of the prop specified
	 * mostly used to simplify api to state and give familiar options object for namespace
	 * @param prop {String} smart property string that allows for dot notation and bracket notation for arrays
	 * @param skipValueCheck looking to optimise we can skip checking the prop here cause it was already done
	 * @returns {*}
	 */
	const get = (prop, skipValueCheck) => {

		// if no prop fail
		if (!skipValueCheck && !hasValue(prop)) {
			const errorMessage = 'Property required to get state';
			console.error(errorMessage);
			return;
		}

		// return a clone of the requested data.
		const data = _state[prop];

		return hasValue(data) ? JSON.parse(data) : data;
	};

	/**
	 * Set a property in the state and notify any watchers of the change/creation
	 * If path to the property doesn't exist .set() will create the path until it can add it to state.
	 * @param prop {String} smart property string that allows for dot notation and bracket notation for arrays
	 * @param newValue
	 */
	const set = (prop, newValue) => {

		// if no prop fail
		if (!hasValue(prop)) {
			const errorMessage = 'Property string required to set state';
			console.error(errorMessage);
			return;
		}

		// save old state value for use in callback via bus
		const oldValue = get(prop, true);

		// Check if value changed
		const isNotChanged = deepEqual(oldValue, newValue);
		// if not changed do not update
		if (isNotChanged) return;

		// mutate actual state.
		_state[prop] = JSON.stringify(newValue);

		// notify all watchers of property change
		bus.emit(`store_watcher_${prop}`, {
			old: oldValue,
			new: newValue,
		});

	};

	/**
	 * Watch a property of state and call the callback when changes happen
	 * @param prop {String} smart property string that allows for dot notation and bracket notation for arrays
	 * @param callback
	 */

	const watch = (prop, callback) => {

		// ensure valid params
		if (!hasValue(prop) || typeof callback !== 'function') {
			const errorMessage = 'Property string and callback function required to watch property';
			console.error(errorMessage);
		}

		// store in event bus under state
		bus.on(`store_watcher_${prop}`, callback);

		// return the callback in case they want to set that to a variable to unwatch.
		return callback;
	};

	/**
	 * Stop watching a state property
	 * WARNING If no prop is passed it will clear all watchers for the namespace
	 * @param prop {String} name of the property that is being watched
	 * @param callback {Function}  reference to the same function that was created
	 */
	const unwatch = (prop, callback) => {

		// ensure valid params
		if (!hasValue(prop) || typeof callback !== 'function') {
			const errorMessage = 'Property string and callback function required to unwatch property';
			console.error(errorMessage);
		}

		bus.off(`store_watcher_${prop}`, callback);
	};

	const debug = () => {
		console.log({
			state: _state,
			bus: bus.debug(),
		});
	};

	return Object.freeze({
		debug,
		get,
		set,
		watch,
		unwatch,
	});
};
const store = storeMe();

window.storeDebug = store.debug;

const helpers = {
	hasValue,
	deepEqual,
};

export { store, helpers };
